<template>
    <button
        class="btn"
        :class="buttonClasses"
        :type="type"
        v-on="$listeners"
        @click="onSubmit"
        :disabled="disabled"
        :data-testid="dataTestid"
    >
        <img
            v-show="imageSrc"
            class="me-1"
            width="16"
            :src="getImageSrc"
            alt="icon"
        >
        <span
            v-if="submitting"
            class="spinner-border spinner-border-sm"
        />
        <span v-else>
            <slot name="label">
                {{ label }}
            </slot>
        </span>
    </button>
</template>

<script>
    import { logger } from '@/utils/logger'

    export default {
        name: 'FormButton',
        props: {
            label: {
                type: String,
            },
            type: {
                type: String,
                default: 'submit',
            },
            disableButton: {
                type: Boolean,
                default: false,
            },
            submitting: {
                type: Boolean,
                default: false,
            },
            imageSrc: {
                type: String,
                default: null,
            },
            buttonClasses: {
                type: String,
                default: 'btn btn-primary',
            },
            eventName: {
                type: String,
                default: 'click_button_undefined',
            },
            eventProps: {
                type: Object,
                required: false,
            },
            dataTestid: {
                type: String,
                required: false,
            },
        },
        computed: {
            getImageSrc() {
                if (!this.imageSrc) {
                    return null
                }

                return require(`../../assets/images/${this.imageSrc}`)
            },
            disabled() {
                return this.disableButton || this.submitting
            },
        },
        methods: {
            onSubmit() {
                this.$logEvent(this.eventName, this.eventProps)
                let logMessage = `click button - label: ${this.label} - event name: ${this.eventName}`
                if (this.eventProps) {
                    logMessage += `- event props: ${JSON.stringify(this.eventProps)}`
                }
                logger.info(logMessage)
            },
        },
    }
</script>
