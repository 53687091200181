<template>
    <div>
        <validation-provider
            mode="eager"
            v-slot="{ errors }"
            :vid="name"
            :rules="ssnValidation"
        >
            <div class="input">
                <p
                    v-if="prompt || errors.length > 0"
                    class="text-start fw-bold"
                >
                    {{ prompt }}
                </p>
                <cleave
                    :data-private="!lastFourOnly"
                    type="tel"
                    inputmode="decimal"
                    class="form-control has-lock-icon"
                    form-classes="form-classes"
                    :id="name"
                    :options="options"
                    :class="[errors[0] ? 'is-invalid' : null]"
                    :name="name"
                    :placeholder="ssnPlaceHolder"
                    :value="value"
                    v-model="cleaveValue"
                />
                <div class="invalid-feedback text-start">
                    {{ errors[0] }}
                </div>
            </div>
        </validation-provider>
    </div>
</template>

<script>
    import { formInputMixin, formInputCleaveMixin } from '@/mixins/formInputMixin'
    import { i18n } from '@/utils/i18n'

    export default {
        name: 'FormSsnInput',
        mixins: [formInputMixin, formInputCleaveMixin],
        props: {
            lastFourOnly: { type: Boolean, default: false },
            prompt: { type: String, default: '' },
        },
        watch: {
            value: function (value) {
                // This allows outside funcs to set the internal value of this box (i.e. to clear it)
                // Without this, cleave input will ignore external v-model bindings
                this.cleaveValue = value
            },
        },
        computed: {
            ssnPlaceHolder() {
                if (this.lastFourOnly) {
                    return i18n.t('components.formFieldSsn.placeholderLast4')
                } else {
                    return i18n.t('components.formFieldSsn.placeholder')
                }
            },
            ssnValidation() {
                if (this.lastFourOnly) {
                    return 'required|min:4|max:4'
                } else {
                    return 'required|min:9|max:9'
                }
            },

            options() {
                if (this.lastFourOnly) {
                    return {
                        numericOnly: true,
                        prefix: 'XXX-XX-',
                        noImmediatePrefix: true,
                        rawValueTrimPrefix: true,
                        blocks: [7, 4],
                    }
                } else {
                    return {
                        numericOnly: true,
                        blocks: [3, 2, 4],
                        delimiters: ['-'],
                    }
                }
            },
        },
    }
</script>

<style lang="scss" scoped>
    @import '../../styles/components/base/formField';
</style>
